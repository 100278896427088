// Create a countdown timer that takes as input an end date and time value and attaches to an HTML element by ID.

// The timer will count down to the end date and time and display the time remaining in the HTML element.
// The timer will display the time remaining in the following format:
// HH:MM:SS
// Where HH is the number of hours remaining, MM is the number of minutes remaining and SS is the number of seconds remaining.
const countdown = (element) => {
    let days, hours, minutes, seconds
    let end = new Date(element.dataset.endDate)
    let timerNode = document.getElementById(`timer-${element.dataset.id}`)

    const updateTimer = () => {
        let now = new Date()
        let timeLeft = end - now
        // Calculate the number of days, hours, minutes, and seconds left
        days     = Math.floor(timeLeft / 86400000)
        timeLeft = timeLeft % 86400000
        hours    = Math.floor(timeLeft / 3600000)
        timeLeft = timeLeft % 3600000
        minutes  = Math.floor(timeLeft / 60000)
        timeLeft = timeLeft % 60000
        seconds  = Math.floor(timeLeft / 1000)
        if (days > 1) {
            days = days + " days "
        } else {
            days = false
        }
        if (hours < 10) {
            hours = "0" + hours
        }
        if (minutes < 10) {
            minutes = "0" + minutes
        }
        if (seconds < 10) {
            seconds = "0" + seconds
        }

        let timer = `${hours}:${minutes}:${seconds}`

        // Display the result
        timerNode.innerHTML = `${!days ? (hours > 0 ? "in " + timer : " today") : "in " + days}`
    };
    updateTimer()
    return updateTimer;
}

function handleIntersection(entries) {
    entries.forEach(entry => {
        let myInterval;
        if (entry.isIntersecting) {
            // Element is entering the viewport
            let updateTimer = countdown(entry.target);
            myInterval = setInterval(updateTimer, 1000);
        } else {
            // Element is leaving the viewport
            clearInterval(myInterval);
        }
    });
}

const observer = new IntersectionObserver(handleIntersection);

let setCountdown = () => {
    let elements = document.querySelectorAll(".price-new")
    if (elements.length > 0) {
        elements.forEach((element) => {
            observer.observe(element);
        })
    }
}

window.setCountdown = setCountdown;

window.addEventListener("initialized_ODPLIBRARY", function (event) {
    window.setCountdown()
})
